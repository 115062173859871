.select_box {
    margin-bottom: 15px;
    margin-top: 15px;
}

#settings {
    padding: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: #1ED760;
    border-radius: 8px;
    background-color: #F7F7F7;
    box-shadow: 2px 2px 8px #CCCCCC;
}

#checkbox_group {
    text-align: left;
    margin-bottom: 15px;
}

#slider_group {
    height: 100px;
    text-align: left;
    margin-bottom: 5px;
}

#year_slider {
    width: 49%;
    float: left;
    position: relative;
}

#similar_slider {
    width: 49%;
    float: right;
    position: relative;
}

.widget_title {
    font-size: 0.8em;
    margin-bottom: 0px;
}

.checkbox p {
    font-size: 0.8em;
    margin-bottom: 0px;
}

.slider_container {
    display: flex;
    height: 60px;
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
    justify-content: center;
}

.slider {
    position: relative;
    width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
    position: absolute;
}

.slider__track,
.slider__range {
    border-radius: 3px;
    height: 5px;
}

.slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
}

.slider__range {
    background-color: #1ED760;
    z-index: 2;
}

.slider__left-value,
.slider__right-value {
    /* color: #dee2e6; */
    font-size: 12px;
    margin-top: 20px;
}

.slider__left-value {
    left: 0px;
}

.slider__right-value {
    right: 0px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
    z-index: 3;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 180px;
    background-color: #ffffff;
    color: black;
    border-color: #1ED760;
    border-style: solid;
    border-width: 1px;
    word-break: break-word;
    text-align: left;
    bottom: 100%;
    opacity: 0.95;
    padding: 5px 5px;
    border-radius: 6px;
    font-size: 0.8em;
    position: absolute;
    z-index: 9;
}

.tooltip_l .tooltiptext_l {
    left: 50%;
    margin-left: -20px;
}

.tooltip_r .tooltiptext_r {
    left: 50%;
    margin-left: -90px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    /* At the bottom of the tooltip */
    margin-left: -5px;
    margin-top: 1px;
    border-width: 5px;
    border-style: solid;
    border-color: #1ed760 transparent transparent transparent;
}

.tooltip_l .tooltiptext_l::after {
    left: 20px;
}

.tooltip_r .tooltiptext_r::after {
    left: 90px;
}