.app_container {
  min-height: 100vh;
  position: relative;
}

.main_app {
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
  padding-bottom: 130px;
}

.page {
  max-width: 1000px;
  margin: auto;
  background-color: #FFFFFF;
}

.hdr_container {
  background-color: #1ED760;
  display: flex;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}

.hdr_img {
  object-fit: cover;
  width: 100%;
  max-width: 700px;
}

.footer {
  background-color: #D5D5D5;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0px;
}

.footer p {
  margin-top: 29px;
}

#bmac {
  height: 50px;
  margin-top: 15px;
  margin-left: 20px;
}

.profile {
  height: 54px;
  margin-bottom: 10px;
}

#prof_img_holder {
  float: left;
}

#prof_text_holder {
  padding-top: 17px;
  padding-left: 20px;
  float: left;
}

.profile_img {
  height: 50px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #1ED760;
  box-shadow: 2px 2px 8px #CCCCCC;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8em;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
}

td {
  word-break: break-word;
}

tr:nth-child(even) {
  background-color: #eeeeee;
}

button {
  font-family: inherit;
  font-size: 0.9em;
  padding: 5px;
  margin: 10px 0px;
  box-shadow: 2px 2px 8px #CCCCCC;
}

li {
  margin-bottom: 5px;
}

#deselect-btn {
  margin-left: 10px;
}

hr {
  background-color: #1ED760;
  height: 2px;
  border-style: None;
}

h1 {
  text-align: center;
}

h2 {
  margin-top: 30px;
  margin-bottom: 0px;
}

.playlist_container {
  max-height: 600px;
  margin-bottom: 20px;
  overflow-y: auto;
}

.summ_title {
  margin-bottom: 0px;
}

.summ_container {
  overflow: auto;
}

.loading_container {
  display: flex;
  justify-content: center;
}

.loading {
  height: 150px;
  margin-top: 30px;
  animation: rotation 2s infinite linear;
}

.loading_txt {
  text-align: center;
  font-size: 2.5em;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

#tracks_container {
  max-height: 400px;
  margin-top: 10px;
  margin-bottom: 20px;
  overflow-y: auto;
}

#summ1 {
  width: 50%;
  float: left;
}

#summ2 {
  width: 50%;
  float: right;
}

.output_container {
  padding: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #1ED760;
  border-radius: 8px;
  background-color: #F7F7F7;
  box-shadow: 2px 2px 8px #CCCCCC;
}

.text_container {
  overflow: auto;
  padding-bottom: 20px;
}

.text {
  min-height: 38px;
  border-color: hsl(0deg 0% 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-family: inherit;
  font-size: 1em;
  padding: 8px 8px;
}

.title_text {
  width: 100%;
  box-sizing: border-box;
}

.desc_text {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
}

.out_title {
  font-size: 0.8em;
}

#out1 {
  width: 100%;
}

#out2 {
  width: 100%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  z-index: 10;
}

.modal-main {
  position:fixed;
  background: white;
  text-align: center;
  word-break: break-word;
  border-radius: 10px;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  overflow: auto;
  z-index: 12;
}

.modal-main h2 {
  color: black;
  margin-top: 0px;
  background-color: #1ED760;
  padding: 10px;
}

.modal-main p {
  margin: 16px;
}

.clickReset {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}